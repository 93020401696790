<template>
  <div class="feedbacks-page card card-padding h-100" :class="{ 'is-show-filter': showFilter }">
    <PageHeader
      @showFilter="showFilter = true"
      :disabled="filterLoading"
      :showFilter="showFilter"
      :search-input-text="$t('Search request...')"
      @handleInput="handleSearch"
    >
      <template #filter-badges>
        <BankFilterBadges
          :filterData="getFilterForm"
          :allData="filterAllData"
          :not-styles="true"
          @getSize="filterCount = $event"
        />
      </template>
    </PageHeader>

    <div class="feedbacks-wrapper">
      <div
        v-if="showFilter"
        class="filter feedbacks-wrapper__filter"
      >
        <p
            @click="showFilter = false"
            class="closeFilter">
          <img width="20" src="/media/buying/icons/close.svg" alt="">
        </p>
        <BankRequestFilter
          :initialFilters="filterData.filter"
          :filterCount="filterCount"
          :countries="bankCountries"
          :productAreas="bankProductAreas"
          :companies="companies"
          :priority="priority"
          @apply-filters="applyRequestFilters"
        />
      </div>
      <div class="feedbacks-wrapper__list">
        <div
            v-if="!loading && requests.length"
            v-for="request in requests"
            :key="request.id"
            class="mt-8"
        >
          <BankRequestItem
            :requestData="request"
            :showFilter="showFilter"
            @handle-toggle-status="toggleRequestStatus(request)"
          />
        </div>
        <div
            v-else-if="!loading && !requests.length"
            class="h-100 d-flex align-items-center justify-content-center"
        >
          <CustomLoader height="400px" v-if="filterLoading" />
          <RequestIdeaEmptyState
            v-else
            :title="$t('No Request Found')"
            :text="filterCount
              ? $t('There are no open requests to be found. This might have happened due to the filters applied.')
              : $t('There are no open requests yet. Please check back later.')
            "
            :icon-path="filterCount
              ? '/media/buying/icons/no-request-found-filter.png'
              : '/media/buying/icons/no-request-found.svg'
            "
          />
        </div>
        <CustomLoader height="400px" v-else />
      </div>
    </div>
  </div>
</template>
<script>
import {setCurrentPageBreadcrumbs, setCurrentPageCounter} from "@/core/helpers/breadcrumb";
import PageHeader from "@/buying-teams/shared-components/feedbacks/PageHeader";
import BankRequestFilter from "@/buying-teams/pages/bank/requests/BankRequestFilter";
import store from "@/store";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import {RequestStatusEnum} from "@/store/enums/request/RequestStatusEnum";
import {RequestStatusModel} from "@/store/models/request/RequestStatusModel";
import BankRequestItem from "@/buying-teams/pages/bank/requests/BankRequestItem";
import {BankRequestFilterModel} from "@/store/models/filter/bank/BankRequestFilterModel";
import BankFiltersTypeEnum from "@/store/enums/bank/BankFiltersTypeEnum";
import RequestIdeaEmptyState from "@/buying-teams/shared-components/utils/RequestIdeaEmptyState";
import BankFilterBadges from "@/buying-teams/shared-components/filter/BankFilterBadges";

  export default {
    name: "BankRequests",
    components: {
      BankFilterBadges,
      RequestIdeaEmptyState,
      BankRequestItem,
      BankRequestFilter,
      CustomLoader,
      PageHeader
    },
    data() {
      return {
        filterLoading: true,
        showFilter: false,
        searchTimeoutId: null,
        filterCount: 0,
        filterData: {
          type: "request",
          search: "",
          show_closed_items: false,
          filter: {
            product_areas: [],
            time_period: "*",
            countries: [],
            customers: [],
            priority: [],
            show_closed_items: false
          }
        }
      }
    },
    computed: {
      requests() {
        return store.getters.getBankRequestsData
      },
      loading() {
        return store.getters.getBankRequestsLoading
      },
      businessCountries() {
        return store.getters.businessCountries;
      },
      businessProductAreas() {
        return store.getters.businessProductAreas;
      },
      companies() {
        if (!store.getters.getBankFiltersState.requests.customers) {
          return []
        }
        return store.getters.getBankFiltersState.requests.customers.map(c => {
          return {
            id: c.id,
            name: c.name,
            icon_path: c.icon_path
          }
        })
      },
      bankCountries() {
        return store.getters.getBankFiltersState.requests.countries;
      },
      bankProductAreas() {
        return store.getters.getBankFiltersState.requests.product_areas;
      },
      bankRequestFilter() {
        return store.getters.bankRequestFilter;
      },
      priority() {
        return store.getters.priority;
      },
      timePeriods() {
        return store.getters.timePeriods;
      },
      getFilterForm() {
        return {
          product_areas: this.filterData.filter.product_areas,
          time_period: this.filterData.filter.time_period,
          countries: this.filterData.filter.countries,
          customers: this.filterData.filter.customers,
          priority: this.filterData.filter.priority,
        }
      },
      filterAllData() {
        return {
          all_product_areas: this.bankProductAreas,
          all_time_period: this.timePeriods,
          all_countries: this.bankCountries,
          all_customers: this.companies,
          all_priority: this.priority,
        }
      }
    },
    async mounted() {
      setCurrentPageBreadcrumbs({
        title: this.$t('Requests'),
        subTitle: this.$t('Requests from Customers across all countries and products')
      })
        store.commit('SAVE_BANK_REQUEST_LOADING', true);
        await store.dispatch('initFiltersData', { type: BankFiltersTypeEnum.REQUEST });

      if (!this.bankRequestFilter) {
        this.filterData.filter.product_areas = this.bankProductAreas;
        this.filterData.filter.countries = this.bankCountries;
        this.filterData.filter.customers = this.companies.map(c => c.id);
        this.filterData.filter.priority = this.priority.map(p => p.value);
      } else {
        this.setFilters(this.bankRequestFilter)
      }
      await this.getRequests(this.filterData);

      this.filterLoading = false;
    },
    methods: {
      setFilters(filters) {
        this.filterData.filter = filters;
        this.filterData.show_closed_items = filters.show_closed_items
      },
      async applyRequestFilters(filters) {
          setCurrentPageCounter({counter: null});
          store.commit('SAVE_BANK_REQUEST_LOADING', true);
          this.setFilters(new BankRequestFilterModel(filters));
        await this.getRequests(this.filterData);
      },
      async getRequests(filter) {
        await store.dispatch("getBankRequests", filter);
        setCurrentPageCounter({counter: this.requests.length})
      },
      async toggleRequestStatus(request) {
        let status = request.status.code === RequestStatusEnum.OPEN ? RequestStatusEnum.CLOSED : RequestStatusEnum.OPEN
        const params = {
          id: request.id,
          status: status
        }
        store.dispatch('toggleRequestStatus', params)
          .then(res => {
            if (res) {
              request.status = new RequestStatusModel(status);
            }
          })
      },
      async handleSearch(event) {
        let search = event.target.value

        if (search !== this.filterData.search && this.searchTimeoutId) clearTimeout(this.searchTimeoutId)
        this.filterData.search = search;

        this.searchTimeoutId = setTimeout(async () => {
          await this.getRequests(this.filterData);
        }, 500);
      }
    }
  };
</script>
<style lang="scss" scoped>
.filter-badges {
  width: 21px;
  height: 21px;
  background: #E22D21;
  border: 1px solid #FFFFFF;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  left: -18px;
}
</style>
